import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
// import ContactInfo from '@widgets/ContactInfo'
// import Commitment from '@widgets/Commitment'
import {graphql} from 'gatsby'
import { useTranslation } from "react-i18next"

const PageContact = (props) => {
  const {t} = useTranslation()

  return <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header={t("Let's Connect")}
          // subheader='FlexiBlog theme comes with a pre-made contact form component.
					// You can integrate this form with serverless services such as Formspree, Getform,
					// FormKeep and others to receive form submissions via email.'
        />
        <Divider />
        <ContactForm />
      </Main>
      {/* <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar> */}
    </Stack>
  </Layout>
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }`  

export default PageContact
